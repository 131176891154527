@import "_normalize.css";
.ant-btn-primary {
  background-color: #555251 !important;
  border-color: #555251 !important;
}
@font-face {
  font-family: "Roboto";
}
html {
  font-size: 18px;
}
html * {
  font-family: "Roboto";
  letter-spacing: 2px;
}
body {
  background: #fff;
  color: #333;
  line-height: 1.5;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Roboto", Helvetica, sans-serif;
}
a:hover {
  color: gray;
}
.App {
  margin: auto;
  padding: 1px;
}
body {
  background-color: #fff;
  min-height: calc(100vh - 100px);
  background: #fff;
  background-attachment: fixed;
}
.accountForm {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 70vw;
  margin: 0 auto;
  margin-bottom: 32px;
}
.inner {
  padding: 1em;
  margin: 0 auto;
}
.inner--border {
  border: 1px solid #aaa;
  border-radius: 5px;
}
.inner--drawer {
  text-align: center;
}
.icon {
  height: 24px;
  width: 24px;
}
.constrain {
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px;
}
.ConfirmEmailPage {
  margin-top: 10em;
  text-align: center;
}
.DirectoryFilter,
.event-filters {
  padding: 21px;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.DirectoryFilter .toggle,
.event-filters .toggle {
  margin-top: 7px;
}
@media (max-width: 768px) {
  .event-filters {
    padding: 0;
  }

  .event-filters .filter-row {
    margin: 0.5rem auto;
  }
  .event-filters .filter-row .btn-rounded {
    margin: 0.8rem auto;
  }
}
.filter-action {
  margin-top: 16px;
}
.ProfileStartPage form {
  margin: 55px auto;
}
.ProfileStartPage header {
  height: 200px;
}
.ProfileStartPage h1 {
  text-align: center;
}
.ProfileStartPage .user-company-container {
  margin-top: 100px;
}
.ProfileStartPage-inner {
  max-width: 70%;
  margin: auto;
}
@media (max-width: 1300px) {
  .ProfileStartPage-inner {
    max-width: 100%;
    margin: 0;
  }
}
@media (max-width: 400px) {
  .ProfileStartPage-inner {
    margin: 0 3em;
  }
}
.ProfileInital {
  margin-bottom: 3em;
}
.ProfileInital header {
  height: 200px;
}
.products-grid {
  display: flex;
  flex-wrap: wrap;
}
.products-grid .ProductGridItem {
  margin: 0.5em;
}
#page {
  padding-top: 89px;
  max-width: 1440px;
  margin: auto;
}
.img-rounded {
  border-radius: 50%;
}
.flex-row {
  display: flex;
}
.align-center {
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.dmap-dgrid-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .dmap-dgrid-wrapper {
    display: block;
  }
  .dmap-dgrid-wrapper .DirectoryMap {
    width: 100%;
  }
  .dmap-dgrid-wrapper .DirectoryMap .dmap-inner {
    width: 100%;
  }
}
.DirectoryGrid {
  background: #fff;
  width: 30%;
}
.DirectoryGrid .dgrid-inner {
  width: 100%;
  padding: 0 5px 5px 5px;
  max-height: 75vh;
  overflow: scroll;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .DirectoryGrid .dgrid-inner {
    width: 100vw;
  }
}
.DirectoryMap {
  width: 70%;
}
.DirectoryMap .dmap-inner {
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 70vw;
  height: 75vh;
  max-height: 85vh;
  width: 100%;
  overflow: hidden;
}
.DirectoryGridItem {
  border: none;
}
.DirectoryGridItem .wrapper {
  height: 216px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}
.DirectoryGridItem .wrapper img {
  max-width: 100%;
}
.DirectoryGridItem .ant-card-body {
  padding: 0;
  border: none;
}
.DirectoryGridItem .card-body {
  padding: 10px;
  height: 100px;
}
.DirectoryGridItem .card-body h4 {
  padding: 0;
  margin: 0;
  font-weight: 900;
  height: 40px;
  overflow: hidden;
  margin-bottom: 6px;
}
.DirectoryGridItem .card-body .review,
.DirectoryGridItem .card-body .location {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* Addition lines for 2 line or multiline ellipsis */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.EditStoreInfo {
  border: 1px solid #aaa;
}
.EditBusinessPage {
  min-height: 70vh;
}
.events-wrapper {
  padding: 33px;
}
@media (max-width: 768px) {
  .events-wrapper {
    padding: 33px 0;
  }
}
.form-actions {
  margin: 64px auto;
}
.form-actions button.btn-rounded {
  width: 164px;
}
.details-actions {
  margin: 12px auto 24px auto;
}
.user-company-container {
  height: 50vh;
}
#post-editorial,
#post-body {
  margin: auto;
  max-width: 900px !important;
  padding: 6em 2em;
  margin-bottom: 10em;
}
#post-body a {
  font-weight: bold;
  text-decoration: underline;
}
#post-body ul {
  padding: 0;
  margin: 0;
  display: block;
  /* list-style-type: none; */
}
#post-body li {
  display: list-item;
  width: 100%; /* sometimes firefox gives issue*/
  word-break: break-all;
}

#post-body table {
  border-collapse: collapse;
  width: 100%;
}

#post-body td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.big-bottom-padding {
  padding-bottom: 10em;
}
.megatron {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}
.megatron .btn-mega {
  margin-top: 3px;
  display: inline-block;
}
@media (max-width: 768px) {
  .social-icons-list {
    text-align: center;
    z-index: -1;
  }
}
.blogs-container {
  margin-top: 2rem;
}

.ant-btn-default[disabled],
.ant-btn-default[disabled]:hover,
.ant-btn-default[disabled]:focus,
.ant-btn-default[disabled]:active {
  color: #00000040 !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-btn-default.btn-solid {
  color: #fff;
  background-color: #555251;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.ant-btn-default.btn-solid:hover {
  color: #555251;
  background-color: #fff;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.ant-btn-default.btn-solid:focus {
  color: #fff;
  background-color: #555251;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-rate:hover {
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-bordered {
  color: #555251;
  background-color: #fff;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.btn-bordered:hover {
  color: #d9d9d9;
  background-color: #555251;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-bordered:focus {
  color: #555251;
  background-color: #fff;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-orange {
  color: #fff;
  background-color: #2a2725;
  border-color: #2a2725;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.btn-orange-hov {
  color: #fff;
  background-color: #2a2725;
  border-color: #2a2725;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.btn-orange-hov:hover {
  color: #2a2725;
  border-color: #2a2725;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.blue-ghost {
  color: #555251;
  border-color: #555251;
  background: transparent;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.blue-ghost:hover {
  background: #555251;
  color: #fff;
  border-color: #555251;
}
.btn-hamburger {
  background: #555251;
  color: #fff;
  border: none;
}
.btn-dropdown {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #555251;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-dropdown:hover {
  color: #d9d9d9;
  background-color: #555251;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-dropdown:active {
  background-color: #555251;
}

.btn-dropdown:focus {
  border-color: #555251;
  color: #d9d9d9;
  background-color: #555251;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.btn-filter {
  display: inline-block;
  background: transparent;
  color: #b1b1b1;
  font-size: 1rem;
  margin: 5px 5px;
  width: 100%;
  text-align: center;
  border-right: 1px solid #b1b1b1 !important;
}
.btn-filter:last-of-type {
  border: none;
}
.btn-filter.active {
  color: #2a2725 !important;
}
.btn-filter input[type="checkbox"] {
  display: none;
}
@media (max-width: 589px) {
  .btn-filter {
    font-size: 0.7rem;
  }
}
input[type="checkbox"] + label {
  text-transform: uppercase;
  position: relative;
  z-index: 4;
  height: 30px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  outline: 0;
}
.ant-btn-default {
  border-color: #555251;
  color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.btn-orange {
  color: #fff;
  background-color: #2a2725;
  border-color: #2a2725;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.btn-carousel {
  font-size: 1rem;
  color: #333;
}
@media (max-width: 668px) {
  .btn-carousel {
    font-size: 0.8rem;
  }
}
.btn-lg {
  height: 40px;
  font-size: 16px;
  letter-spacing: 2px;
}
.btn-rounded {
  border-radius: 60px;
}
.btn-link {
  font-size: 28px;
  background: #aaa;
}
.btn-on {
  background: #2a2725 !important;
  border: none;
  font-weight: 900;
  transition: 0.3s all;
}
.btn-on:focus {
  color: #fff;
}
.btn-transparent {
  width: 100%;
  word-wrap: break-word;
  border: none;
  box-shadow: none;
  background: none;
  font-weight: 600;
  color: #333;
}
.btn-transparent:hover,
.btn-transparent:focus {
  background: none;
}
.btn-transparent .ant-icon {
  color: #333 !important;
}
.btn-secondary {
  background-color: #aaa;
  border-color: #aaa;
  color: #fff;
}
.btn-warning {
  color: orange;
  background-color: #fff;
  border-color: orange;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  margin-right: 24px;
}
.btn-warning:hover {
  color: #fff;
  background-color: orange;
  border-color: orange;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.ant-upload {
  width: 100%;
}
.ant-modal-footer button {
  border-radius: 60px;
}
#hamburger {
  border: none;
  box-shadow: none;
  padding: 5px;
  width: 50px;
  height: 45px;
  margin-top: 30px;
}
#hamburger:hover {
  background: lightgrey;
  color: white;
}
#hamburger:focus {
  color: white;
}

.btn-mega {
  max-width: 430px;
  margin: 0;
  padding: 0;
  background: rgba(255, 93, 0, 0.4);
}
.btn-icon {
  background: transparent;
  border: none;
}
.btn-icon:hover {
  cursor: pointer;
}
#call-to-action {
  background-color: #555251 !important;
}
.btn-icon:active {
  color: #555251;
}
.btn-icon:visited {
  color: #b1b1b1;
}
.user-nav {
  font-size: 14px;
  text-transform: none;
  margin-left: 1em;
}
.account-menu {
  border-top: 1px solid #aaa;
}
.AccountNav,
.SideNav {
  min-width: 200px;
  border-top: 1px solid #aaa;
}
.AccountNav ul,
.SideNav ul {
  border-right: none;
}
.AccountNav .ant-menu .ant-menu-item,
.SideNav .ant-menu .ant-menu-item {
  margin: 0;
  font-weight: 700;
  border-bottom: 1px solid #aaa;
}
.AccountNav .ant-menu .ant-menu-item a,
.SideNav .ant-menu .ant-menu-item a {
  color: #333;
}
@media (max-width: 768px) {
  .collapsible-sider {
    display: none;
  }
}
.ant-tabs-bar {
  margin: 0;
}
.profile-menu {
  margin-top: -44px;
}
.Footer {
  display: flex;
  justify-content: space-between;
  padding: 16px 60px;
  border-top: 1px solid #d3d3d3;
  gap: 8px;
}
.Drawer {
  background: #fff;
  width: 100%;
  font-size: 1em;
  padding: 1em 0.5em 1em 0.5em;
  display: flex;
  justify-content: space-between;
}
.Footer .nav-wrapper,
.Drawer .nav-wrapper {
  display: flex;
}
.Footer .nav-wrapper .nav-menu,
.Drawer .nav-wrapper .nav-menu {
  text-transform: uppercase;
  display: inline-block;
  overflow: hidden;
  list-style: none;
  padding: 0;
}
.Footer .nav-wrapper .nav-menu .nav-item,
.Drawer .nav-wrapper .nav-menu .nav-item {
  margin: 16px 0 16px 0;
}
.Footer a.nav-link,
.Drawer .nav-wrapper .nav-menu a.nav-link {
  margin: 0 0 0 21px;
  font-size: 1rem;
  color: navbar-grey;
}
.Footer .nav-link.active,
.Drawer .nav-link.active {
  color: #555251;
  text-decoration: none;
}
.Footer .list-item,
.Drawer .list-item {
  margin: 12px auto;
}
.ant-menu-item-group-title {
  text-align: center;
}
p.dropdown-divider {
  color: #555251 !important;
  text-transform: uppercase;
  margin-left: 2em;
}
.nav-menu-2 {
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  height: 0px;
  max-width: 100%;
  font-size: 1rem;
}
.Banner {
  text-align: left;
  margin-bottom: 24px;
}
#featured-banners {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden;
}
@media (max-width: 425px) {
  #featured-banners {
    display: block;
    text-align: center;
  }
  #featured-banners h2 {
    font-size: 1.3rem;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  #featured-banners h2 {
    font-size: 0.8rem;
  }
  #featured-banners p {
    font-size: 0.6rem;
  }
}
#featured-banners .featured-banners--narrow {
  margin: 12px;
  display: inline-block;
  width: 336px;
}
#featured-banners .featured-banners--narrow .Banner:nth-child(odd) img {
  max-height: 252px;
}
#featured-banners .featured-banners--narrow .Banner:nth-child(even) img {
  max-height: 340px;
}
#featured-banners .featured-banners--wide {
  margin: 12px;
  display: inline-block;
  width: 456px;
}
@media (max-width: 425px) {
  #featured-banners .featured-banners--wide {
    width: 336px;
  }
}
#featured-banners .featured-banners--wide .Banner:nth-child(odd) img {
  max-height: 310px;
}
#featured-banners .featured-banners--wide .Banner:nth-child(even) img {
  max-height: 280px;
}
.double-half-banner {
  display: block;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas: "item-1 item-2";
  align-items: stretch;
}
@media (max-width: 900px) {
  .double-half-banner {
    grid-template-columns: repeat(1, auto);
    grid-template-areas: "item-1" "item-2";
  }
}
.double-half-banner .double-half-container {
  overflow: hidden;
}
.double-half-banner .double-half-container img {
  height: 100%;
  width: 100%;
  max-height: 250px;
  margin: 0;
  overflow: hidden;
  object-fit: stretch;
}
.double-half-banner .double-half-container img:hover {
  transition: all 8s;
  transform: scale(1.2);
}

.vertical-banner {
  display: block;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: stretch;
}

.vertical-banner .vertical-banner-container {
  overflow: hidden;
}
.vertical-banner .vertical-banner-container img {
  height: 30vh;
  width: 100%;
  margin: 0;
  overflow: hidden;
  object-fit: stretch;
}
.vertical-banner .vertical-banner-container img:hover {
  transition: all 8s;
  transform: scale(1.2);
}
form.profile-setup .wrapper {
  max-width: 900px;
}
form.profile-setup .card-body {
  padding-top: 120px;
}

.advertiser-form--lead {
  font-size: 24px;
}
@media (max-width: 1000px) {
  .advertiser-form--lead {
    font-size: 18px;
  }
}
@media (max-width: 475px) {
  .advertiser-form--lead {
    margin-left: auto;
    margin-top: 120px;
    position: relative;
  }
}
.seperator {
  max-width: 100%;
  text-align: center;
  border-bottom: 1px solid #aaa;
  line-height: 0.1em;
  margin: 20px 0 20px;
}
.seperator span {
  background: #fff;
  padding: 0 10px;
}
form label {
  font-weight: 600;
}
form .editor {
  background: rgba(189, 189, 189, 0.05);
}
.product-edit-form {
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}
.products-edit {
  background: rgba(0, 0, 0, 0.05);
}
.products-edit .inner > div {
  display: flex;
  justify-content: space-between;
}
.products-edit .inner > div .form-control {
  width: 33%;
}
.EditPostForm .form-container,
.EditProductPage .form-container,
.NewPostForm .form-container {
  border: solid 1px #bdbdbd;
  border-radius: 4px;
  background-color: #fdfdfd;
  margin-bottom: 24px;
}
.EditPostForm .form-container .form-header,
.EditProductPage .form-container .form-header,
.NewPostForm .form-container .form-header {
  border-bottom: solid 1px #bdbdbd;
  margin: 0;
}
.EditPostForm .form-container input#title,
.EditProductPage .form-container input#title,
.NewPostForm .form-container input#title {
  height: 60px;
  font-size: 24px;
}
.EditPostForm .form-container .ql-editor,
.EditProductPage .form-container .ql-editor,
.NewPostForm .form-container .ql-editor {
  border-radius: 5px;
  min-height: 350px;
}
.EditPostForm .form-container .editor-actions,
.EditProductPage .form-container .editor-actions,
.NewPostForm .form-container .editor-actions {
  display: flex;
  justify-content: space-between;
}
.EditPostForm .form-container .editor-actions a,
.EditProductPage .form-container .editor-actions a,
.NewPostForm .form-container .editor-actions a {
  font-size: 16px;
}
.EditPostForm .form-container .editor-actions .action,
.EditProductPage .form-container .editor-actions .action,
.NewPostForm .form-container .editor-actions .action {
  margin: 0 1em;
}
.ql-editor {
  height: 568px;
  border-radius: 5px;
  background: #fff;
}
.ql-toolbar.ql-snow {
  border: none !important;
}
.ql-container {
  border-top: 1px solid #aaa !important;
  border-radius: 6px;
}
.quill-form-explain {
  color: #f00;
}
.has-error .ql-container {
  border: 1px solid #f00 !important;
}
.details-menu {
  overflow-y: hidden;
  background-color: #fff;
}

.details-menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: unset;
  cursor: pointer;
}
.details-menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover::after {
  border-bottom: unset;
}
.checkbox-menu-item {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.checkbox-menu-item span {
  color: #555251;
  padding: 0 5px;
}

.EditStoreInfo {
  background: #fff;
}

.ad-box {
  border: 1px solid #ddd;
  border-top: none;
  align-content: center;
  padding: 1em;
}
.FreeAdsForm > div {
  min-height: 200px;
}
.FreeAdsForm h3 {
  font-weight: 900;
  color: #555251;
}
.FreeAdsForm h4 {
  font-weight: 900;
  color: #555251;
  text-align: right;
  padding-right: 3em;
  padding-top: 1em;
}
.FreeAdsForm ul {
  margin: 0;
  margin-left: -10px;
}
.FreeAdsForm .btn-blue.ant-btn {
  margin-left: 0;
  width: 50%;
}
.ad-Description {
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  padding: 2em;
}
.ad-Description h3 {
  font-weight: 900;
  color: #0223a4;
}
.eventPostForm {
  width: 100%;
  max-width: 45em;
  margin: 0 auto;
}
.eventPostForm .eventActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4em;
}
.stripe-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "stripe-aside stripe-card-form";
  column-gap: 2em;
}
.stripe-form-container .StripeElement,
.stripe-form-container .stripe-input {
  display: block;
  margin: 0 0 1em 0;
  padding: 7px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #fff;
}
.stripe-form-container input::placeholder {
  color: #bfbfbf;
}
.StripeElement,
.stripe-input {
  display: block;
  margin: 0 0 1em 0;
  padding: 7px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #fff;
}
.loading:after {
  content: "";
  animation: loadingdot 2s infinite 0.5s;
}
.upgrade-checkout {
  border: 1px solid #ddd;
  border-top: none;
  padding: 2em;
}
.upgrade-checkout h3 {
  font-weight: 900;
  color: #0223a4;
}
.modalExit .ant-modal-close {
  background: #fff;
  color: #d3d3d3;
}
input.date-picker {
  border: 1px solid #d9d9d9;
  height: 2.3em;
  border-radius: 5px;
}
div.react-datepicker__header {
  background: #fff;
}
.react-datepicker__day--outside-month {
  visibility: hidden;
}
div.react-datepicker__today-button {
  background: #fff;
  color: #2a2725;
}
.ad-box {
  border: 1px solid #ddd;
  border-top: none;
  align-content: center;
  padding: 1em;
}
.PlacementForm h3 {
  font-weight: 900;
  color: #555251;
}
.PlacementForm h4 {
  font-weight: 900;
  color: #555251;
}
.PlacementForm ul {
  margin: 0;
  margin-left: -10px;
}
.PlacementForm .btn-blue.ant-btn {
  float: right;
  width: 15%;
}
.OnboardingContainer {
  margin: 0 auto;
}
.OnboardingContainer > .onboarding-steps {
  margin-bottom: 16px;
}
.selectMembership {
  outline: 1px solid #e8e8e8;
  margin-bottom: 3em;
  text-align: center;
  background: #fff;
  border: 5px solid transparent;
  color: #555251;
}
.selectMembership h1 {
  color: #555251;
}
.selectMembership h2 {
  color: #555251;
}
.selectMembership:hover {
  border: 5px solid #555251;
  cursor: pointer;
}
.selectedMembership {
  outline: 1px solid #e8e8e8;
  margin-bottom: 3em;
  text-align: center;
  background: #fff;
  border: 5px solid #555251;
  color: #555251;
}
.selectedMembership:hover {
  cursor: pointer;
}
.selectedMembership h1 {
  color: #555251;
}
.selectedMembership h2 {
  color: #555251;
}
@-moz-keyframes loadingdot {
  0% {
    content: "";
    opacity: 1;
  }
  30% {
    content: ".";
  }
  60% {
    content: "..";
  }
  90% {
    content: "...";
  }
}
@-webkit-keyframes loadingdot {
  0% {
    content: "";
    opacity: 1;
  }
  30% {
    content: ".";
  }
  60% {
    content: "..";
  }
  90% {
    content: "...";
  }
}
@-o-keyframes loadingdot {
  0% {
    content: "";
    opacity: 1;
  }
  30% {
    content: ".";
  }
  60% {
    content: "..";
  }
  90% {
    content: "...";
  }
}
@keyframes loadingdot {
  0% {
    content: "";
    opacity: 1;
  }
  30% {
    content: ".";
  }
  60% {
    content: "..";
  }
  90% {
    content: "...";
  }
}
.jumbo {
  width: 100%;
}
.carousel-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ant-carousel .slick-prev:before {
  content: "";
}
.ant-carousel .slick-next:before {
  content: "";
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.home-tab {
  margin-top: 1em;
  color: #000;
  font-size: 20px;
  overflow: visible;
}

.homepage-container {
  margin: auto;
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  color: #000;
  font-size: 20px;
}
.candidate-tag,
.home-tab-tag {
  margin: 2px 10px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #f2f2f2;
  background: none;
  border-radius: 10px;
  color: #000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.candidate-tag:hover,
.home-tab-tag:hover {
  border: 1px solid #555251;
  cursor: pointer;
}
.home-tab-tag-selected {
  margin: 2px 10px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #f2f2f2;
  background: #555251;
  border-radius: 10px;
  color: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.home-tab-tag-selected:hover {
  border: 1px solid #555251;
  cursor: pointer;
}
.react-datepicker-wrapper {
  width: 100%;
}
.Home {
  width: 100vw;
}
input::placeholder {
  font-family: "Roboto" !important;
}
a.nav-link {
  color: #181818;
}
a {
  color: #2a2725;
}
.form-control {
  border: 1px solid #bdbdbd;
}
.blue {
  color: #555251;
}
.facebook {
  background: #4267b2;
}
.whiteout {
  background: none;
}
.invalid-feedback {
  display: block;
}
.half {
  width: 45%;
}
.capitalized {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.normalize {
  color: #000;
  text-decoration: none;
}
.default-bg {
  background-color: #fff;
}
.lead {
  font-size: 16px;
  line-height: 2;
  padding: 2em 10em;
}
@media (max-width: 900px) {
  .lead {
    padding: 1em;
  }
}
.lead p {
  text-align: left;
}
.pricing .ant-card .choose {
  height: 170px;
}
.pricing .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}
.pricing .ant-card-body .ant-list-item .ant-list-item-content {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.pricing {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pricing .price-card-wrapper {
  position: relative;
}
.pricing .price-card-wrapper .recommended {
  box-sizing: border-box;
  width: 20em;
  position: absolute;
  text-align: center;
  padding-top: 0.8em;
  left: 1em;
  top: -2em;
  height: 4em;
  background: #ddd;
  color: dark-grey;
}
.pricing .card {
  padding: 1px;
  margin: 1em;
  width: 20em;
  overflow: hidden;
}
.pricing .card .card-body {
  height: 15em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.pricing .card .list-group {
  margin: 0;
  padding: 0;
}
.pricing .card .list-group-item {
  border: none;
  margin: 0;
}
.pricing .card .list-group-item:nth-child(even) {
  background-color: #f7f7f7;
}
.banner-image-wrapper {
  position: relative;
  margin: 0 auto 15em auto;
  width: 100%;
}
.banner-image-wrapper .instructions {
  position: absolute;
  left: 5px;
  top: 5px;
}
.banner-image-wrapper .image-wrapper {
  display: grid;
  align-items: center;
  box-sizing: border-box;
  height: 200px;
  max-width: 100%;
  position: absolute;
  overflow: hidden;
  border-bottom: 1px solid #333;
  background: #fff;
}
.banner-image-wrapper .image-wrapper img {
  margin: auto;
  width: 100%;
}
.banner-image-wrapper button {
  text-decoration: underline;
}
.banner-image-wrapper button.file-upload,
.banner-image-wrapper button.remove-image {
  cursor: pointer;
  border: none;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8);
  margin: 12px;
  text-decoration: none;
}
.banner-image-wrapper button.file-upload.light,
.banner-image-wrapper button.remove-image.light {
  color: #333;
  font-weight: 900;
  border-radius: 2px;
  padding-top: 2px;
}
.banner-image-wrapper button.file-upload.light:hover,
.banner-image-wrapper button.remove-image.light:hover {
  color: #2a2725;
  background: #fff;
}
.banner-image-wrapper button.file-upload.light span,
.banner-image-wrapper button.remove-image.light span {
  text-decoration: none;
}
.banner-image-wrapper button.file-upload.dark,
.banner-image-wrapper button.remove-image.dark {
  color: #333;
}
.banner-image-wrapper button.file-upload input.file-input,
.banner-image-wrapper button.remove-image input.file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 20px;
  opacity: 0;
  z-index: 2;
}
.banner-image-wrapper button.file-upload {
  position: absolute;
  right: 100px;
  top: 150px;
}
.banner-image-wrapper button.remove-image {
  position: absolute;
  right: 10px;
  top: 150px;
  z-index: 2;
}
.BannerImageUploader {
  position: relative;
  margin: 0 auto 15em auto;
  width: 100%;
}
.BannerImageUploader .instructions {
  position: absolute;
  left: 5px;
  top: 5px;
}
.BannerImageUploader .image-wrapper {
  display: grid;
  align-items: center;
  box-sizing: border-box;
  height: 200px;
  max-width: 100%;
  position: absolute;
  overflow: hidden;
  border-bottom: 1px solid #333;
  background: #fff;
}

.ant-modal-content .BannerImageUploader .image-wrapper {
  position: relative;
}
.BannerImageUploader .image-wrapper img {
  margin: auto;
  width: 100%;
}
.BannerImageUploader button {
  text-decoration: underline;
}
.BannerImageUploader button.file-upload,
.BannerImageUploader button.remove-image {
  cursor: pointer;
  border: none;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8);
  margin: 12px;
  text-decoration: none;
}
.BannerImageUploader button.file-upload.light,
.BannerImageUploader button.remove-image.light {
  color: #333;
  font-weight: 900;
  border-radius: 2px;
  padding-top: 2px;
}
.BannerImageUploader button.file-upload.light:hover,
.BannerImageUploader button.remove-image.light:hover {
  color: #2a2725;
  background: #fff;
}
.BannerImageUploader button.file-upload.light span,
.BannerImageUploader button.remove-image.light span {
  text-decoration: none;
}
.BannerImageUploader button.file-upload.dark,
.BannerImageUploader button.remove-image.dark {
  color: #333;
}
.BannerImageUploader button.file-upload input.file-input,
.BannerImageUploader button.remove-image input.file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 20px;
  opacity: 0;
  z-index: 2;
}
.BannerImageUploader button.file-upload {
  position: absolute;
  right: 100px;
  top: 150px;
}
.BannerImageUploader button.remove-image {
  position: absolute;
  right: 10px;
  top: 150px;
  z-index: 2;
}
.CoverImageUploader .instructions {
  text-align: center;
}
.CoverImageUploader .image-wrapper {
  margin: auto;
  box-sizing: border-box;
  max-height: auto;
  max-width: 65vw;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
@media (max-width: 996px) {
  .CoverImageUploader .image-wrapper {
    max-width: 100%;
  }
}
.CoverImageUploader .image-wrapper img {
  margin: auto;
  max-width: 100%;
}
.CoverImageUploader .file-upload-container {
  display: flex;
  justify-content: center;
}
.CoverImageUploader .file-upload {
  overflow: hidden;
  background: none;
  margin: 0.5em 0;
}
.CoverImageUploader .file-upload .file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  height: 100%;
}
.brand-image-wrapper {
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  border: 1px solid #b1b1b1;
  text-align: center;
}
.brand-image-wrapper img {
  margin: auto;
  max-width: 102%;
}
.ant-modal-body .BrandImageUploader {
  margin-bottom: 0px;
}
.ant-modal-body .BrandImagePreviewContainer {
  display: flex;
  flex-direction: column;
}
.ant-modal-body .BannerImageUploader .image-wrapper {
  width: 100%;
}
.BrandImageUploader {
  text-align: left;
  position: relative;
  width: 100%;
  margin-bottom: -70px;
}
.BrandImageUploader .title {
  transform: translate(152px, 100px);
}
.BrandImageUploader .image-wrapper {
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  border: 1px solid #b1b1b1;
  text-align: center;
}
.BrandImageUploader .image-wrapper img {
  margin: auto;
  max-width: 102%;
}
.BrandImageUploader button.file-upload,
.BrandImageUploader button.remove-image {
  background-color: #fff;
  cursor: pointer;
  border: none;
  color: #333;
  transform: translate(145px, 100px);
  overflow: hidden;
}
.BrandImageUploader button.file-upload:hover,
.BrandImageUploader button.remove-image:hover {
  color: #2a2725;
}
.BrandImageUploader button.file-upload span,
.BrandImageUploader button.remove-image span {
  text-decoration: underline;
}
.BrandImageUploader button.file-upload input.file-input,
.BrandImageUploader button.remove-image input.file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 20px;
  opacity: 0;
}
.BusinessHoursPicker .hours-list li {
  display: flex;
}
.BusinessHoursPicker .hours-list li span {
  margin-right: 1.1em;
}
.ServiceDetailsPage .hours-list {
  text-align: left;
}
.uploader-wrapper {
  border: none;
  padding: 2em;
  padding-top: 100px;
  content: " ";
  height: 200px;
  display: block;
  background: #aaa;
  margin-bottom: 8em;
}
.ProductGridItem {
  z-index: 2;
  overflow: hidden;
}
.ProductGridItem:hover {
  cursor: pointer;
}
.ProductGridItem .product-name {
  height: 40px;
  overflow: hidden;
}
.ProductGridItem .ProductDetailsModal .title {
  margin-bottom: 2em;
}
.product-details {
  border-radius: 0px;
}
.product-details .description {
  display: inline-block;
  max-width: 500px;
  white-space: pre-wrap;
}
.product-details .image-wrapper {
  text-align: left;
}
.product-details img {
  width: 100%;
  margin: auto;
}
.product-details .price-rates > div {
  text-align: right;
  font-size: 1.1em;
}
.product-pricerates {
  display: flex;
  justify-content: flex-end;
}
.product-pricerates .pricerate {
  display: inline-block;
  margin-right: 2em;
}
.product-pricerates .pricerate .price {
  font-size: 1.3em;
  color: #2a2725;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.product-pricerates .pricerate .amount {
  text-align: center;
}
.ingredients {
  display: flex;
}
.ingredients .ingredient {
  margin-right: 1em;
}
.ingredients .ingredient .label {
  font-weight: 600;
  display: inline-block;
  margin-right: 0.2em;
  text-transform: uppercase;
}
.product-list-item-category {
  color: #aaa;
}
.products-list {
  background: #fff;
}
@media (max-width: 789px) {
  .blog-categories {
    margin: 2rem;
  }
}
.CategoryAnchorTags--container {
  padding: 2rem 3rem;
}
.CategoryAnchorTags .ant-carousel .slick-list {
  left: -15px;
  height: 80px;
}
.CategoryAnchorTags
  div.ant-carousel
  div.slick-slider.slick-initialized
  div.slick-list
  div.slick-track
  div.slick-slide
  div
  div.carousel-item-wrapper {
  display: grid !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #b1b1b1;
  height: 80px;
}
.CategoryAnchorTags
  div.ant-carousel
  div.slick-slider.slick-initialized
  div.slick-list
  div.slick-track
  div.slick-slide
  div
  div.carousel-item-wrapper
  p {
  width: 344px;
  margin: auto;
  overflow: hidden;
  padding: 8px 32px;
}
@media (max-width: 425px) {
  .CategoryAnchorTags {
    display: none;
  }
}
.ProductListItem {
  border-bottom: 1px solid #d3d3d3;
  padding: 20px 0;
}
.ProductListItem p {
  position: absolute;
  height: 60px;
  overflow: hidden;
}
.ProductListItem .actions > * {
  margin: auto 1em;
}
.product-item {
  background: none;
  flex-wrap: wrap;
}
.product-item > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1em;
}
.product-item a {
  margin: auto;
  color: #333;
  text-transform: uppercase;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #2a2725;
  text-align: center;
  margin: auto;
}
.marker {
  cursor: pointer;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
}
.marker-details {
  display: flex;
  align-items: center;
}
.marker-details > div {
  margin-right: 12px;
}
.Review {
  padding: 22px auto;
  border-bottom: 1px solid #fff;
}
.BlogGridItem {
  background-color: #fbfbfb;
  box-shadow: 0 2px 18px -1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 1em auto 1em auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.BlogGridItem .body {
  font-size: 14px;
  padding: 3px 8px;
  color: #808080;
}
.BlogGridItem .blog-vote {
  margin-right: 0.5em;
  display: flex;
  margin: 0 1em 0 1em;
}
.BlogGridItem .upvote {
  height: 17px;
  width: 17px;
  margin: 0.2em 0 0.2em 0;
  font-size: 22px;
  color: #008000;
}
.BlogGridItem .upvote:hover {
  color: #b1b1b1;
}
.BlogGridItem .nullvote {
  height: 17px;
  width: 17px;
  margin: 0.2em 0 0.2em 0;
  font-size: 22px;
  color: #b1b1b1;
}
.BlogGridItem .nullvote:hover {
  color: #008000;
}
.BlogGridItem a {
  text-transform: none;
  text-decoration: none;
  width: 100%;
}
.BlogGridItem .titleTop {
  display: flex;
}
.BlogGridItem .title {
  display: flex;
  justify-content: space-between;
  padding: 3px 8px;
}

.BlogGridItem .date {
  color: #aaa;
  padding: 3px 8px;
  font-size: 14px;
}
.BlogGridItem .origin {
  color: #aaa;
  padding: 3px 8px;
  font-size: 12px;
  font-style: italic;
}
.BlogGridItem .inline-list {
  padding: 0;
  margin: 0;
  bottom: 0;
  font-size: 22px;
}
.BlogGridItem .inline-list span {
  font-weight: 300;
}
.BlogGridItem .inline-list ul {
  list-style-type: circle;
}
.BlogGridItem .inline-list li {
  color: #b1b1b1;
  display: inline;
  font-style: italic;
  font-size: 12px;
}
.BlogGridItem .inline-list li:after {
  content: ",";
}
.BlogGridItem h2 {
  margin: 0 0 0 0.3em;
  width: 100%;
  height: 10%;
}
.BlogGridItem .actions {
  margin: 0 0 1em 0;
}
.BlogGridItem .commentsBody {
  margin-bottom: 24px;
  text-indent: 16px;
}
.BlogGridItem .commentsBody > .body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.BlogGridItem .commentsBody .link {
  color: #808080;
  padding: 3px 8px;
}
.BlogGridItem .commentsInputContainer {
  position: absolute;
  bottom: 0px;
}
.BlogGridItem .noCapSection .noCapBody {
  margin-top: 8px;
  padding-left: 16px;
}
.BlogGridItem .noCapSection .noCapBody > .origin {
  text-align: right;
  padding-right: 24px;
}
.BlogGridItem .noCapSection .candidateAvatars {
  padding: 0px 8px;
}
.BlogGridItem .noCapSection .candidateAvatars .ant-avatar {
  margin-right: 4px;
}
.BlogGridItem .noCapSection .noCapBody > .item {
  display: flex;
}
.delete {
  color: #f00;
}
.page {
  margin-top: 89px;
}
.page-heading {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 40px 0 0 0;
  margin: 0;
  color: #555251;
}
.link-heading {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 40px 0 0 40px;
  margin: 0;
}
.link-heading2 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #2a2725 !important;
  margin: 0;
}
.link {
  font-size: 16px;
  color: #2a2725;
  text-transform: uppercase;
  text-decoration: underline;
}
.edit-post-uploader {
  border-bottom: 1px solid muted-grey;
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 1.5;
}
.checkbox-filter-menu {
  background-color: transparent;
  border-top: 1px solid muted-grey;
  border-bottom: 1px solid muted-grey;
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 1.5;
}
.title {
  color: #111;
}
.menu-item {
  font-size: 16px;
  font-weight: 600;
  margin: 8px auto;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: space-between;
}
.jumbotron {
  height: 400px;
  width: 100%;
  background-repeat: no-repeat;
}
.mobile-view {
  display: none;
}
@media (max-width: 1024px) {
  .mobile-view {
    display: block;
  }
}
.mobile-view img {
  margin: 0;
}
.right-mobile {
  width: 100%;
}
.top-left-mobile {
  width: 100vw;
}
.bottom-left-mobile {
  width: 50vw;
}
.bottom-right-mobile {
  width: 50vw;
}
.JumboGrid {
  box-sizing: border-box;
  margin: auto;
}
.JumboGrid .jumbo-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-areas: "item-1 item-1 item-2 item-2" "item-3 item-4 item-2 item-2";
  grid-template-rows: 28vh 32vh;
}
@media (max-width: 1024px) {
  .JumboGrid .jumbo-container {
    display: none;
  }
}
.JumboGrid .jumbo-image-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.JumboGrid .jumbo-image-wrapper img {
  height: 100%;
  width: 100%;
  margin: 0;
  transition: all 8s;
  object-fit: stretch;
}
.JumboGrid .jumbo-image-wrapper img:hover {
  transform: scale(1.2);
}
.JumboGrid .jumbo-item1 {
  grid-area: item-1;
  object-fit: stretch;
}
@media (max-width: 1024px) {
  .JumboGrid .jumbo-item1 {
    display: hidden;
  }
}
.JumboGrid .jumbo-item1 .jumbo-image-wrapper img {
  height: 100%;
  width: 100%;
}
.JumboGrid .jumbo-item2 {
  grid-area: item-2;
  object-fit: stretch;
}
.JumboGrid .jumbo-item2 .jumbo-image-wrapper img {
  height: 100%;
  width: 100%;
}
.JumboGrid .jumbo-item3 {
  grid-area: item-3;
  object-fit: stretch;
}
@media (max-width: 1024px) {
  .JumboGrid .jumbo-item3 {
    display: hidden;
  }
}
.JumboGrid .jumbo-item3 .jumbo-image-wrapper img {
  height: 100%;
  width: 100%;
}
.JumboGrid .jumbo-item4 {
  grid-area: item-4;
  object-fit: stretch;
}
@media (max-width: 1024px) {
  .JumboGrid .jumbo-item4 {
    display: hidden;
  }
}
.JumboGrid .jumbo-item4 .jumbo-image-wrapper img {
  height: 100%;
  width: 100%;
}
.JumboGrid .jumbo-item {
  align-items: stretch;
  overflow: hidden;
}
.JumboSearch {
  background: #eaeaea;
  padding: 20px 12px 20px 12px;
  margin: 2px;
}
@media (max-width: 475px) {
  .JumboSearch {
    margin: 1rem;
  }
}
.JumboSearch h1#search-heading {
  color: #fff;
  margin-top: 2rem;
  padding: 0;
  line-height: 3rem;
  font-size: 3rem;
  text-shadow: 1px 1px 2px #000;
}
@media (max-width: 1235px) {
  .JumboSearch h1#search-heading {
    font-size: 2.2rem;
  }
}
@media (max-width: 1024px) {
  .JumboSearch h1#search-heading {
    display: block;
  }
}
@media (max-width: 768px) {
  .JumboSearch h1#search-heading {
    display: block;
    font-size: 2rem;
  }
}
.JumboSearch .jumbo-search__container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 3fr 2fr 2fr 2fr;
  grid-template-rows: 1fr;
}
@media (max-width: 1024px) {
  .JumboSearch .jumbo-search__container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .JumboSearch .jumbo-search__container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto-fill);
    grid-gap: 16px;
  }
}
@media (max-width: 569px) {
  .JumboSearch .jumbo-search__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 16px;
  }
}
.JumboSearch button,
.JumboSearch input {
  height: 45px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.JumboSearch input.ant-input {
  font-size: 1rem;
}
.JumboSearch .ant-input-affix-wrapper {
  width: inherit;
}
.JumboSearch button#call-to-action {
  margin-top: 1rem;
  right: 0;
  background: #555251;
  border: none;
  color: #fff;
  padding-left: 3rem;
  padding-right: 3rem;
  font-weight: 600;
  margin: 0;
}
.JumboSearch button#call-to-action:hover {
  background: #2a2725 !important;
}
.headline {
  font-size: 2.5em;
  bottom: 5%;
  left: 10%;
  position: absolute;
  color: #fff;
}
.headline h3 {
  color: #fff;
}
.featured {
  text-align: center;
}
/* .events-table {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
} */
.events-table thead tr th {
  background: #f5f5f5;
  border: 0;
  color: #2a2725;
  padding: 12px 16px;
}
.events-table .ant-table-thead th.ant-table-cell {
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.events-table .ant-table-thead th.ant-table-cell::before {
  content: unset !important;
}
.events-table .ant-table-thead th.ant-table-cell:last-child {
  border-right: 1px solid #d9d9d9;
}
.events-table thead tr th:hover {
  background: #fff !important;
}
.events-table thead tr .ant-table-column-sorter {
  color: #bdbcbb;
}
.events-table thead tr .ant-table-column-sort {
  background: #fff;
}

.event h2,
.service h2 {
  margin-top: 45px;
  margin-bottom: 6px;
}
@media (max-width: 720px) {
  .event h2,
  .service h2 {
    margin-top: 1rem;
  }
}
.event .description,
.service .description {
  padding: 33px 0;
  letter-spacing: 1px;
  white-space: pre-wrap;
}
.inner {
  white-space: pre-wrap;
}
.details-header {
  text-align: center;
}
.details-bg {
  background: #fff;
  height: 200px;
  overflow: hidden;
  background-size: cover;
}
.details-share-list {
  height: 50px;
  display: inline-block;
  padding: 10px;
}
.details-share-list .details-share-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 24px;
  margin-right: 12px;
}
.brand-img-wrapper {
  background: #fff;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  left: 24px;
  top: 90px;
  border: 1px solid #b1b1b1;
  z-index: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.profile-img {
  text-align: center;
  width: 100%;
}
@media (max-width: 800px) {
  .brand-img-wrapper {
    height: 150px;
    width: 150px;
    top: 10px;
  }
}
.profile-menu-wrapper {
  position: absolute;
  top: 206px;
  display: flex;
  align-items: center;
  align-content: stretch;
  justify-content: space-around;
}
.tags-list {
  margin-top: 16px;
}
.tags-list > div {
  margin: 16px auto;
}
#static-map {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-bottom: 2em;
}
.icon-list {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
}
.icon-list > div {
  margin: 8px auto;
  overflow-wrap: break-word;
}
.icon-list .anticon {
  margin-right: 12px;
}
.service-details-list .anticon {
  margin-right: 12px;
}
.service-details-list .service-details-list-wrapper {
  display: inline-block;
  margin: auto;
  padding: 1px;
}
li.ant-menu-item {
  background: #fff;
}
.category-list .category-list-item,
.category-list > div {
  margin: 8px auto;
  overflow-wrap: break-word;
  line-height: 2;
}
.category-list .category-list-item .icon,
.category-list > div .icon {
  display: inline-block;
  padding-right: 10px;
  margin-right: 12px;
  margin-bottom: 12px;
}
.category-list .category-list-item .icon img,
.category-list > div .icon img {
  height: 38px;
  width: 38px;
}
.category-list .category-list-item .name,
.category-list > div .name {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: #aaa;
}
@media (max-width: 768px) {
  .category-list {
    display: flex;
    flex-wrap: wrap;
  }
  .category-list .category-list-item .capitalized.name,
  .category-list .amenity-list-item .capitalized.name {
    display: none;
  }
}
.social-icons-list {
  float: right;
  padding: 8px 20px;
}
.social-icons-list a {
  margin: 5px;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
}
#take-survey {
  color: #2a2725;
  text-decoration: underline;
}
.BlogPost .candidate-tags-container {
  display: flex;
  overflow: auto;
  white-space: nowrap;
}
.candidate-tag {
  display: flex;
  margin: 0 0.5em 1em;
}
.candidate-tag .candidate-bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
}
.candidate-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.office-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.BlogPost .videoContainer {
  width: 550;
  margin: 0 auto;
  padding: 10px;
}

.BlogPost .videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25;
  height: 0;
}
.BlogPost .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 100%;
}

.BlogPost header {
  flex-direction: column;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.BlogPost header .header-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.BlogPost header .header-buttons .button-group {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 400px) {
  .BlogPost header .button-group {
    width: 100%;
  }
}

.BlogPost header h2 {
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .BlogPost header {
    padding: 0;
  }

  .BlogPost header h2 {
    margin-top: 1em;
  }
}
.BlogPost form {
  max-width: 920px;
  width: 72vw;
}
@media (max-width: 996px) {
  .BlogPost form {
    padding: 2rem;
    width: 100%;
  }
}
@media (max-width: 700px) {
  .BlogPost form {
    padding: 2rem 0 0;
  }
}

.BlogPost .ql-editor {
  min-height: 160px;
}
.BlogPost .ql-editor:focus {
  box-shadow: 0 0 0 1px black;
}

.BlogPost #post-editorial,
.BlogPost #post-body {
  margin: 0 auto;
  padding: 24px;
  font-size: 15px;
  /* display: inline; */
  white-space: pre-line;
}
.BlogPost #post-body img {
  max-width: 100%;
}
@media (max-width: 700px) {
  .BlogPost #post-editorial,
  .BlogPost #post-body {
    padding: 24px 0;
  }
}
.BlogPost .meta {
  padding: 0 24px 0 24px;
  text-align: center;
}
.BlogPost .meta h3 {
  text-transform: capitalize;
  margin: 24px auto;
}
.BlogPost .meta .nickname {
  font-weight: 600;
}
.BlogPost .meta .date {
  color: #aaa;
  font-size: 12px;
}
.BlogPost .footer {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 900px;
  padding: 2em;
}
.BlogPost .footer .button-group {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 700px) {
  .BlogPost .footer {
    padding: 2em 0;
  }
}
@media (max-width: 480px) {
  .BlogPost .footer .button-group {
    width: 100%;
  }
}
.loader-container {
  margin: auto;
  text-align: center;
  padding: 100px 0;
}
.spinner-container {
  display: flex /* establish flex container */;
  flex-direction: column /* make main axis vertical */;
  justify-content: center /* center items vertically, in this case */;
  align-items: center /* center items horizontally, in this case */;
  height: 220px;
}
.ql-size-small {
  font-size: 11px;
}
.ql-size-medium {
  font-size: 16px;
}
.ql-size-large {
  font-size: 24px;
}
.ql-size-huge {
  font-size: 36px;
}

.Footer .title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
}
.line {
  height: 5px;
  border-radius: 3px;
  margin: 5px auto;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.ant-time-picker-panel-input {
  background-color: transparent;
}
.MegaLinks {
  position: relative;
  margin-bottom: 5em;
}
.MegaLinks .wrapper {
  height: 150px;
  overflow: hidden;
}
.MegaLinks .wrapper:hover {
  opacity: 0.8;
}
.MegaLinks img {
  margin: 2px;
  width: 110%;
  object-fit: cover;
  background: #333;
}
.MegaLinks .mega-link {
  width: 100%;
  overflow: hidden;
}
.MegaLinks .mega-link .mega-text {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 35px;
  left: 0;
  margin: auto;
  color: #fff;
  text-shadow: 0 0 12px #555251;
  font-size: 3rem;
}
.FeaturedServicesSlider {
  margin-bottom: 4em;
  margin-left: 25px;
  margin-right: 25px;
}
.FeaturedServicesSlider span.ant-divider-inner-text h3.slider-title {
  word-wrap: break-word;
  text-transform: uppercase;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin: 1em;
  text-align: right;
}
.actions a {
  color: #555251;
  font-weight: 900;
}
.FeaturedBlogPostsSlider {
  margin-bottom: 4em;
}
.FeaturedBlogPostsSlider .featured-slide {
  margin: 1em auto;
}
.FeaturedBlogPostsSlider .details {
  position: relative;
  top: -310px;
  z-index: 3;
}
.FeaturedBlogPostsSlider .details .pull-right {
  display: flex;
  justify-content: flex-end;
}
.FeaturedBlogPostsSlider .details .tag {
  background-color: #333;
  padding: 0.5em 1em;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
.FeaturedBlogPostsSlider .details h3 {
  margin: 0 0;
  padding: 5px 16px;
  position: relative;
  top: 185px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 700;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
@media (max-width: 768px) {
  .FeaturedBlogPostsSlider .details h3 {
    top: 100px;
  }
}
.FeaturedBlogPostsSlider .details .meta {
  margin: 0 0;
  padding: 5px 16px;
  position: relative;
  top: 175px;
  color: #fff;
  font-weight: 400;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
@media (max-width: 768px) {
  .FeaturedBlogPostsSlider .details .meta {
    top: 100px;
  }
}
.FeaturedBlogPostsSlider .wrapper {
  max-width: 375px;
  max-height: 320px;
}
.FeaturedBlogPostsSlider .wrapper img.cover {
  height: 320px;
  display: absolute;
  margin: auto;
  max-width: 100%;
}
@media (max-width: 768px) {
  .FeaturedBlogPostsSlider .wrapper img.cover {
    height: 200px;
  }
}
.FeaturedBlogPostsSlider .actions {
  margin: 1em;
  text-align: right;
  position: relative;
  top: -110px;
}
.FeaturedBlogPostsSlider .actions a {
  color: #555251;
  font-weight: 700;
}
.ant-divider-horizontal.ant-divider-with-text:before,
.ant-divider-horizontal.ant-divider-with-text-left:before,
.ant-divider-horizontal.ant-divider-with-text-right:before,
.ant-divider-horizontal.ant-divider-with-text:after,
.ant-divider-horizontal.ant-divider-with-text-left:after,
.ant-divider-horizontal.ant-divider-with-text-right:after {
  border-top: 1px solid #333;
}
.button-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 858px) {
  .button-grid {
    justify-content: flex-start;
  }
}
@media (max-width: 570px) {
  .button-grid {
    max-width: 100%;
  }
}
.ant-modal-content {
  border-radius: 0px;
}
.ant-layout {
  background: none;
}
.ant-input-group-addon {
  background: none;
  border-left: none;
}
#hide-pass {
  border-right: none;
}
.show-password {
  cursor: pointer;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: top;
  top: 0px;
}
.ServiceDetailsPage .ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
  cursor: pointer;
}
.SideNav .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none;
  margin-bottom: 2rem;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: 1px solid #808080;
  background: #fff;
  cursor: pointer;
}
.ant-menu-root.ant-menu-vertical .ant-checkbox-inner {
  margin-left: 0.5rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #555251;
  border-color: #555251;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(103, 103, 103, 0.2);
  box-shadow: 0 0 0 2px rgba(103, 103, 103, 0.2);
}

.ant-menu-root.ant-menu-vertical .ant-checkbox-inner:hover {
  background-color: #555251;
  border-color: #555251;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

/*
.btn-dropdown:hover {
  color: #d9d9d9;
  background-color: #555251;
  border-color: #555251;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.btn-dropdown:active{
  background-color: #555251;
}

.btn-dropdown:focus{
  border-color: #555251;
  color: #d9d9d9;
  background-color: #555251;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  box-shadow: 0 0 0 2px rgba(87,168,233, .2);
} */

.ant-checkbox:focus {
  border-color: #555251;
  color: #d9d9d9;
  background-color: #555251;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(115, 117, 120, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: black;
}
.ant-checkbox-checked::after {
  border: 1px solid black;
  box-shadow: none;
}
/* .container:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* .ant-checkbox-unchecked .ant-checkbox-outer:focus {
    color: #d9d9d9;
    background-color: #555251;
    border-color: #555251;
    outline: 0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
} */

.ant-time-picker-input[disabled] {
  background-color: #fff;
  color: #333;
}
input.ant-input.ant-input-lg {
  color: #333 !important;
}
#categories
  .ant-select-selection
  .ant-select-selection__rendered
  .ant-select-selection__choice:first-child {
  background-color: #2a2725;
  color: #fff;
}
#categories
  .ant-select-selection
  .ant-select-selection__rendered
  .ant-select-selection__choice:first-child
  .ant-select-selection__choice__remove {
  color: #fff;
}
.AdManager {
  min-height: 500px;
  width: 100%;
}
.adManagerImageUploader .form-container {
  border: solid 1px #bdbdbd;
  border-radius: 4px;
  max-width: 880px;
  background-color: #fdfdfd;
  margin-bottom: 24px;
}
.dataRenderGrid {
  display: inline-grid;
  margin: 0;
  grid-auto-rows: 2rem;
  grid-template-columns: 2.5rem 4.5rem 4rem 4.9rem 7.5rem 10rem 4rem;
  grid-gap: 2rem;
  justify-content: space-evenly;
  align-content: right;
  justify-items: start;
}
.dataRenderRow {
  grid-row: 1;
}
.activeAdvert {
  color: #555251;
  font-weight: 900;
  font-size: 15px;
}
.labelsActiveAds {
  display: inline-grid;
  margin: 0;
  font-weight: 900;
  grid-auto-rows: 2rem;
  grid-template-columns: 2.5rem 4.5rem 4rem 4.9rem 7.5rem 10rem 4rem;
  grid-gap: 2rem;
  align-content: right;
  justify-items: start;
  color: #555251;
}
.activeAd {
  grid-row: 1;
}
.subscriptionInfo {
  display: flex;
  justify-content: space-evenly;
}
.subscriptionInfo div {
  line-height: 15px;
}
.subscriptionInfo h3 {
  color: #555251;
  margin-right: 0.3rem;
}
.cardDescriptor {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas: "item-1" "item-2";
  grid-template-rows: 90% 10%;
}
.cardDescriptor .item-2 {
  padding: "5px";
}
.account-stepper {
  margin-bottom: 3em;
}
.divider-wrapper {
  padding-bottom: 90px;
}
.divider {
  position: relative;
  margin-top: 90px;
  height: 1px;
}
.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    #555251,
    transparent
  );
}
.ant-drawer-body {
  background: #fff;
}
.ql-align-center {
  display: flex;
  justify-content: center;
}
.ql-align-right {
  text-align: right;
  width: 100%;
  justify-content: right;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.steps-action {
  margin-top: 24px;
  align-self: flex-end;
  width: 100%;
}
.invoicePreview {
  padding: 1em;
  background: #d3d3d3;
  min-height: 20vh;
}
.invoicePreview2 {
  padding: 1em;
  margin-top: 3em;
  background: #d3d3d3;
  min-height: 20vh;
}
.onboard-heading {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 40px 0 0 0;
  margin: 0;
  color: #555251;
}
/* appearance for checked radiobutton */
.rate-card
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #2a2725;
  border-color: #2a2725;
}
.rate-card .ant-radio-button-wrapper:hover {
  position: relative;
  color: #2a2725;
  border: 1px solid #2a2725;
}
.rate-card
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #2a2725 !important;
  opacity: none;
}
.rate-card
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #2a2725 !important;
  opacity: none;
}
.accountTabSelected {
  text-decoration: underline;
}
.react-datepicker-popper {
  z-index: 3 !important;
}
.bizcard-home-container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1340px;
}
@media (max-width: 528px) {
  .bizcard-home-container {
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .web-search-bar {
    visibility: hidden;
  }
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-top: 7px;
  padding-left: 37px;
}
.black-border-hover {
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  padding: 2px;
}
.black-border-hover:hover {
  border: 3px solid #000;
  padding: 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #363636 !important;
}

.ant-tabs-tab :hover {
  color: #363636 !important;
  font-weight: 500;
}

.ant-tabs-nav :hover {
  color: #363636 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #000000;
  pointer-events: none;
}

.ant-spin-dot-item {
  background-color: black;
}

.ant-input:hover {
  border-color: black;
  outline: none;
}

.ant-input:focus {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-radio-checked .ant-radio-inner {
  border-color: black !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: black;
}

.ant-radio:hover .ant-radio-inner {
  border-color: black;
}

.ant-select-selection {
  background-color: black;
}
.ant-select-item.ant-select-item-option-selected {
  background-color: lightgrey;
  color: black;
}

.ant-picker:hover {
  border-color: black;
  outline: none;
}
.ant-picker:focus {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-picker-input:hover {
  border-color: black;
  outline: none;
}

.ant-picker-input:focus {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-picker-today-btn {
  color: #8c8c8c;
}

.ant-picker-today-btn:hover {
  color: black;
}

.ant-picker-focused {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: 1px solid black;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: black;
}

.ant-picker-header-view button {
  color: #8c8c8c;
}

.ant-picker-header-view button:hover {
  color: black;
}

.ant-form-item-label > label.ant-form-item-required:before {
  color: black;
}

.ant-input-affix-wrapper:hover {
  border-color: black;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-select-selector:focus {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: black;
  border-color: black;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border: 1px dashed black;
}

.ant-input-affix-wrapper-focused {
  border-color: black;
  box-shadow: 0 0 4px #eee;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #555251;
}

#search-keyword {
  background-color: white;
}

.news-cta--tags-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 2em 0;
}

.news-cover-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-cover-image img {
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

@media (max-width: 700px) {
  .news-cover-image img {
    width: 100%;
    padding: 0;
  }
}

.news-cta-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.news-cta--btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.news-cta--btn-container button {
  margin-bottom: 2em;
}
.news-post-new-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-picker-range .ant-picker-active-bar {
  background: black;
}

.ant-pagination-item-active {
  border: 2px solid darkgrey;
}

.ant-pagination-item-active a {
  color: black;
}
.ant-pagination-item-active a:hover a:focus {
  color: darkgrey;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: darkgrey;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: black;
}

.ant-pagination-item a:hover {
  color: darkgrey;
}

.ant-pagination-item:hover {
  border-color: black;
}

.ant-pagination-next .ant-pagination-item-link:hover {
  color: darkgrey;
  border-color: black;
}

.ant-pagination-prev .ant-pagination-item-link:hover {
  color: darkgrey;
  border-color: black;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: black;
  border-bottom: 2px solid black;
}

.ant-card-grid .ant-card-grid-hoverable:hover {
  -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.ant-card-grid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed:hover,
.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed:focus,
.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded:hover,
.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded:focus {
  color: darkgrey;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  border-color: black !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.anticon.anticon-check {
  color: black;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: lightgrey;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background: grey;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed grey;
  border-bottom: 1px dashed grey;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed grey;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed grey;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: lightgrey;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: darkgrey;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: darkgrey;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: lightgrey;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: black;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: black;
}

.ant-progress-status-success .ant-progress-bg {
  background: black;
}

.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  color: black;
}

.anticon.anticon-caret-up.ant-table-column-sorter-up.active {
  color: black;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: black;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: black;
}

.anticon.anticon-close-circle:hover,
.ant-btn.btn-transparent:hover {
  color: lightgrey;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: black;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: black;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: darkgrey;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: black;
}
.ant-table-filter-trigger.active {
  color: black;
}

.ant-btn-link {
  color: black;
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: darkgrey;
}

.ant-switch-checked {
  background-color: black;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: black;
}

.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, black, black);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: black;
}

.office-add-input-divider {
  margin: 4px 0;
}

.office-add-input {
  flex: auto;
}

.office-add-input-plus {
  flex: none;
  padding: 8px;
  display: block;
  cursor: pointer;
  color: #8c8c8c;
}

.office-add-input-divider {
  margin: 4px 0;
}

.office-add-input-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}

.ant-table-thead > tr > th {
  color: white;
  background: grey;
  text-align: center;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  flex-flow: row wrap;
}

.actionContainer button {
  border: unset;
  box-shadow: unset;
  background-color: transparent;
}

.actionButton > .ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButtonText {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  text-align: left;
}

.actionButton .body,
.actionButton .title {
  font-size: 8px;
  padding: 0;
  margin: 0;
}

.commentsContainer > .body {
  text-indent: 16px;
}

.HomeNavigationTabs {
  min-height: 10em;
  width: 70%;
  margin: 0 auto;
}

.candidate-action-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.candidate-questions {
  height: auto;
  margin-top: 3.4em;
}

.queues-actions {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
}

.search-nav-bar {
  width: 90%;
}

.news-row {
  margin: 0 auto;
}

.elections-table .ant-table-thead .ant-table-cell:not(:first-child) {
  border-bottom: 2px solid #bfbfbf;
}

.elections-table .ant-avatar {
  transform: scale(0.8);
}
.elections-table .ant-table-cell {
  background: transparent !important;
  color: #363636;
  border: 0;
  text-align: left;
}

.elections-table .ant-table-cell.ant-table-cell-fix-right,
.elections-table .ant-table-cell .ant-space-item .election-action {
  color: #1890ff;
  background-color: White !important;
}

.ant-btn-icon-only:active,
.ant-btn-icon-only:focus,
.ant-btn-icon-only:hover {
  color: grey;
  border-color: grey;
}

.voting-history .ant-table-thead .ant-table-cell {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.85);
  padding: 12px 16px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}

.voting-history .ant-table-tbody .ant-table-cell {
  border-bottom: 1px solid #d9d9d9;
}

.voting-history .ant-table-tbody .ant-table-cell:first-child {
  border-left: 1px solid #d9d9d9;
}
.voting-history .ant-table-tbody .ant-table-cell:last-child {
  border-right: 1px solid #d9d9d9;
}

.voting-history .ant-table-tbody .ant-table-cell {
  padding: 12px 16px;
}

.voting-history .ant-table-thead .ant-table-cell::before {
  content: unset !important;
}

.voting-history .ant-table-thead th:last-child {
  border-right: 1px solid #d9d9d9;
}

.voting-history .ant-table-thead th {
  border: unset;
}

@media (max-width: 1024px) {
  .OnboardingContainer {
    margin-top: 16px;
  }

  .search-nav-bar {
    width: 100%;
  }

  .HomeNavigationTabs {
    width: 100%;
  }
}

.stripe-checkout .stripe-form {
  padding: 0 8em 2em 8em;
}

.BannerCreation {
  padding: 0 8em 2em 8em;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.ant-tabs-extra-content {
  padding: 0 8px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #ff4d4f;
}

.news-ads-container {
  margin: 0 auto;
}

.layout-content {
  margin: 2em 1em;
  min-height: calc(100vh - 40vh);
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 16px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #555251;
  border-color: #555251;
}

.BlogPost .form-body {
  padding: 0px 16px;
}

.rate-card .ant-radio-button-wrapper {
  border: 0;
}

.find-candidates-title-container {
  text-align: center;
  width: 50%;
  margin: 0 auto 16px auto;
}

.candidate-edit-form-upload {
  height: 13em;
}

li.checkbox-menu-item > .ant-menu-title-content {
  display: flex;
  flex-direction: row-reverse;
}

.ellipse-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.registration-secondary-label,
.registration-secondary-label .ant-form-item-label > label {
  color: lightgray;
  font-style: italic;
}

.loader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(100%, 100%, 100%, 50%);
}

.ant-form-item-control-input-content label.ant-radio-button-wrapper {
  color: #d3d3d3;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-form-item-control-input-content label.ant-radio-button-wrapper-checked {
  color: #555251;
  border-color: #555251;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #555251;
}

@media (max-width: 1024px) {
  .search-bar {
    max-width: 960px;
  }
  .layout-content {
    max-width: 960px;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: inherit;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: unset;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.home-tab.tab-services {
  text-align: center;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.home-tab:not(.tab-services) {
  display: flex;
  justify-content: center;
}

@media (min-width: 769px) and (max-width: 1000px) {
  .layout-content {
    padding: 1em;
  }
}

/* ================================
* UI for Tablet and smaller screens 
================================== */
@media (max-width: 768px) {
  .layout-content {
    max-width: 640px;
    padding: 0 1em;
  }

  .layout-content .constrain {
    padding: 16px 0px;
  }

  .search-bar {
    max-width: 640px;
    padding: 0 1em;
    margin-bottom: 1em;
  }

  .BlogPost .form-body {
    padding: 0px;
  }

  .homepage-container {
    width: 100%;
  }

  .CandidateList > .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  .CandidateList > .ant-card-body .ant-card-grid {
    width: 100%;
    padding: 0;
  }
  .CandidateList > .ant-card-body .ant-card-grid,
  .CandidateList > .ant-card-body .ant-card-grid > div,
  .CandidateList > .ant-card-body .ant-card-grid .ant-space {
    width: 100% !important;
  }
  .CandidateList > .ant-card-body .ant-card-grid > div {
    height: auto !important;
  }
  .CandidateList > .ant-card-body .ant-card-grid .ant-avatar {
    width: auto !important;
    height: auto !important;
  }
  .CandidateList > .ant-card-body .ant-card-grid .ant-space-item {
    width: 100%;
    padding: 0 8px;
  }
  .homepage-container .slick-initialized .slick-slide {
    padding: 0 0.5em;
  }

  .HomeNavigationTabs {
    width: 100%;
  }

  .ant-row .invoicePreview {
    margin-top: 0;
    margin-bottom: 1em;
  }

  .BannerCreation {
    padding: 0;
  }

  .BannerCreation,
  .stripe-checkout .stripe-form {
    padding: 0 2em;
  }

  .candidate-action-buttons {
    justify-content: space-evenly;
  }

  .candidate-questions {
    margin-top: 0;
  }

  .candidate-preview .ant-card-body {
    display: flex;
    justify-content: center;
  }

  .events-table {
    overflow: scroll;
  }

  .queues-actions {
    flex-direction: column;
  }

  .queues-actions button {
    width: 100%;
    margin-bottom: 8px;
  }

  .news-row {
    width: 100%;
  }

  .elections-container,
  .elections-container .ant-space-align-center {
    width: 100%;
  }

  .ServiceDetailsPage h2 {
    text-align: center;
  }
  .ServiceDetailsPage .rating-container {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .ServiceDetailsPage .service-ads {
    margin: 0 auto;
  }

  .ServiceDetailsPage .service-details-list-wrapper img {
    width: 100%;
    margin-bottom: 16px;
  }

  .news-ads-container {
    width: 100%;
    padding: 0 1em;
    margin-top: 1em;
  }

  .rate-card {
    overflow: scroll;
  }

  .find-candidates-title-container {
    width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #b1b1b1 !important;
  }

  .slick-prev {
    left: 0% !important;
    z-index: 1;
  }
  .slick-next {
    right: 0% !important;
    z-index: 1;
  }

  .carousel-item-container {
    width: 100%;
    margin-bottom: 16px;
  }

  .CandidateList .default-image > img {
    max-height: 300px !important;
  }

  .candidate-list-container {
    width: 100%;
  }

  .mobile-username {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13ch;
  }

  .slick-custom-next svg,
  .slick-custom-prev svg {
    font-size: 32px;
  }

  .slick-custom-next {
    right: 0;
  }

  .slick-custom-next,
  .slick-custom-prev {
    line-height: 0;
    position: absolute;
    z-index: 99;
    top: 50%;
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
  }

  .BrandImageUploader .uploader-avatar,
  .BrandImageUploader .uploader-upload-btn {
    text-align: center;
  }

  .BannerImageUploader button.file-upload {
    top: 0px;
    right: 16px;
  }

  .BannerImageUploader button.file-upload.file-change-banner,
  .BannerImageUploader button.file-upload.file-remove-banner {
    position: relative;
    margin-left: 32px;
  }

  .BrandImageUploader .image-wrapper {
    margin: 0 auto;
  }

  .candidate-edit-form-upload {
    height: 20em;
  }

  .candidate-card .ant-card-head {
    padding: 0px;
  }
}

.form-sub-label {
  font-size: 10px;
  font-style: italic;
  color: gray;
}
